import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Product from "../components/product"

const ProductPage = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <Product
        limited
        title="Big Bang"
        description={description}
        shortDescription="Signed and printed beautifully on Hahnemühle museum etching 350 gsm paper using the highest quality museum grade giclée pigment printing, ensuring lasting intense colors and rich tone depth."
        price={70}
        images={[data.bigbang_1, data.bigbang_2]}
        social={data.social}
        gumroadlink="https://gum.co/PLKJF"
        slug="/bigbang/"
      />
    </>
  )
}

const query = graphql`
  fragment image on File {
    childImageSharp {
      fluid(maxWidth: 1340, quality: 95) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query {
    social: file(relativePath: { eq: "bigbang_1.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bigbang_1: file(relativePath: { eq: "bigbang_1.jpg" }) {
      ...image
    }
    bigbang_2: file(relativePath: { eq: "bigbang_2.jpg" }) {
      ...image
    }
  }
`
const description = (
  <>
    <p>
      Signed and printed beautifully on Hahnemühle museum etching 350 gsm paper
      using the highest quality museum grade giclée pigment printing, ensuring
      lasting intense colors and rich tone depth.
    </p>

    <p>Size: 30cm × 40cm</p>

    <p>Numbered edition of 100</p>

    <p>
      All prints are made on heavy quality paper, so all prints are shipped flat
      packaged to ensure delivery of the print in mint condition.
    </p>

    <p>
      The shipment of this print cannot be combined with the shipping cost of
      the pre-orders, because they are shipped from a different location.
    </p>
  </>
)

export default ProductPage
